var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "收款明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (iouReceive) {
    return _c('van-cell', {
      key: iouReceive.id,
      attrs: {
        "title": '收款日期：' + iouReceive.receive_date,
        "value": '￥' + iouReceive.receive_amount,
        "value-class": "cell-value",
        "title-class": "cell-title"
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', [_c('span', [_vm._v("操作员：" + _vm._s(iouReceive.employee.name))]), _c('span', {
            staticStyle: {
              "margin-left": "10px"
            }
          }, [_vm._v("收款方式：" + _vm._s(_vm._f("receiveModeFilter")(iouReceive.receive_mode)))])])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
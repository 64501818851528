import request from '@/utils/request'

export function getClientUnpaids(params) {
  return request({
    method: 'get',
    url: 'client-unpaid',
    params
  })
}

export function getClientUnpaidDetail(params) {
  return request({
    method: 'get',
    url: 'client-unpaid/detail',
    params
  })
}

export function receiveUnpaid(data) {
  return request({
    method: 'post',
    url: `client-unpaid/receive`,
    data
  })
}

export function getClients(params) {
  return request({
    method: 'get',
    url: `client-unpaid/client`,
    params
  })
}

export function addClientIou(data) {
  return request({
    method: 'post',
    url: `client-unpaid/iou`,
    data
  })
}

export function getClientIou(params) {
  return request({
    method: 'get',
    url: `client-unpaid/iou`,
    params
  })
}

export function receiveClientIou(data) {
  return request({
    method: 'post',
    url: `client-unpaid/iou/receive`,
    data
  })
}

export function getClientIouReceive(params) {
  return request({
    method: 'get',
    url: `client-unpaid/iou/receive`,
    params
  })
}

export function getClientUnpaidReceiveDetail(params) {
  return request({
    method: 'get',
    url: `client-unpaid/receive-detail`,
    params
  })
}
